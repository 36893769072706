import { Observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Col, Container , Row, Button} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context } from '..';
import CategoryItem from '../components/CategoryItem';
import TypeButton from '../components/TypeButton';

const Oferta = () => {

    return (
        <>

            <Container className='mh100 container-fluid container-md '>
            
            <p>Договор публичной оферты о продаже товаров Интернет-магазина «Презент» </p>
<p>Публичный договор-оферта интернет-магазина «Презент», сайт www. Презент72.рф Настоящий договор определяет условия приобретения товаров в интернет-магазине Презент, который находится на сайте по адресу в сети Интернет - https://www.презент72.рф </p>
<p>1. ОБЩИЕ ПОЛОЖЕНИЯ:</p>
<p> 1.1. ИП Закутянский Святослав Васильевич, действующая на основании Свидетельства о государственной регистрации физического лица в качестве индивидуального предпринимателя (далее Продавец), публикует настоящий договор для интернет-магазина Презент </p>
 1.2. Настоящий договор является публичным договором-офертой (предложением) в адрес как физических, так и юридических лиц (далее Покупатель) в соответствии со статьей 435 и пунктом 2 статьи 437 Гражданского кодекса Российской Федерации. 
<p> 1.3. Настоящая публичная оферта (далее Оферта) определяет все существенные условия договора между Продавцом и Покупателем (далее Стороны). </p>
 1.4. Договор-оферта может быть акцептована (принята) любым физическим или юридическим лицом на территории Российской Федерации, имеющим намерение приобрести товар, реализуемый ИП Зуевой Кристиной Анатольевной через интернет-магазин Презент, расположенный на сайте www.презент72.рф
<p> 1.5. В случае принятия условий настоящего договора Оферты, физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем </p>
1.6. Покупатель безоговорочно принимает все условия, содержащиеся в Оферте в целом (т.е. в полном объеме и без исключений). 
<p> 1.7. Договор-оферта, а также вся дополнительная информация о Товарах опубликована на сайте www.презент72.рф</p>
 1.8. Интернет-магазин Презент предназначен для организации дистанционного способа продажи Товаров через сеть Интернет, а также для информирования Покупателей о товарах и услугах ИП Зуевой Кристины Анатольевны. 
<p> 1.9. Использование ресурса интернет-магазина Презент для просмотра и выбора товара, а также для оформления заказа является для Покупателя безвозмездным. </p>
 1.10. Весь контент – тексты, мультимедиа и документы, размещенные на www.презент72.рф, являются собственностью ИП Зуевой Кристины Анатольевны. Использование любой информации с сайта www.презент72.рф, в том числе с целью последующего использования на сторонних ресурсах преследуется по закону. Публичное размещение товаров и материалов с сайта www.презент72.рф: в сети Интернет, печатных изданиях, средствах массовой информации, социальных сетях без письменного разрешения ИП Зуевой Кристины Анатольевны запрещено. 
<p> 1.11. В своей деятельности интернет-магазин Презент руководствуется положениями Гражданского кодекса РФ, Законом РФ от 07 февраля 1992 года № 2300-1 «О защите прав потребителей», Правилами продажи товаров дистанционным способом, утвержденными Постановлением Правительства РФ от 27 сентября 2007 года №612 и иным действующим законодательством Российской Федерации. </p>
 1.12. Настоящий договор-оферта может быть изменен ИП Зуевой Кристины Анатольевны без какого-либо специального уведомления, новая редакция настоящего договора-оферты вступает в силу с момента ее размещения на сайте www.презент72.рф, если иное не предусмотрено новой редакцией договора-оферты, и не распространяется на правоотношения между Покупателем и интернет-магазином Презент, возникшие до вступления новой редакции договора-оферты в силу. Действующая редакция договора-оферты всегда находится на интернет-странице по адресу: https://презент72.рф/offerta 
<p>2. ПРЕДМЕТ ДОГОВОРА ОФЕРТЫ</p>
 2.1. Продавец обязуется передать в собственность Покупателю, а Покупатель обязуется оплатить и принять заказанные в интернет-магазине Презент Товары. 
<p> 2.2. Товаром в интернет-магазине Презент являются все товары, находящиеся на сайте www.презент72.рф </p>
 2.3. Для визуальной демонстрации товаров в каталоге могут быть использованы фотографии из интернета. 
<p> 2.4. Товары защищены действующим законодательством об интеллектуальной собственности, предназначены для личного использования Покупателем. </p>
3. МОМЕНТ ЗАКЛЮЧЕНИЯ ДОГОВОРА ОФЕРТЫ
<p> 3.1. Настоящий договор заключается между Покупателем и Продавцом в момент оформления и оплаты заказа. </p>
 3.2. Покупатель считается принявшим акцепт Оферты в момент оформления и оплаты заказа. 
<p> 3.3. Покупатель сообщает о намерении приобрести Товар путем оформления заказа и перечисления денежных средств в счет оплаты Товара на расчетный счет Продавца (в случае безналичной оплаты), либо  через сервис безналичной электронной оплаты. </p>
 3.4. В случае акцепта Оферты Покупатель безоговорочно принимает все условия данного договора, и рассматривается как лицо, вступившее с ИП Зуевой Кристиной Анатольевной в договорные отношения. 
<p>4. ТОВАР И ПОРЯДОК ПРИОБРЕТЕНИЯ ТОВАРА</p>
4.1. Цена Товара в интернет-магазине Презент указана в рублях РФ за единицу Товара. 
<p>4.2. Указанная в интернет-магазине Презент цена Товара может быть изменена Продавцом в одностороннем порядке, при этом цена на заказанный и оплаченный Покупателем Товар изменению не подлежит. </p>
4.3. Общая итоговая сумма заказа, указывается в разделе «Корзина» в строке «Сумма заказа». 
<p>4.4. Товар, представленный на Сайте, по качеству и упаковке соответствует ГОСТу и ТУ, что подтверждается соответствующими документами (сертификатами и т.д.). </p>
4.5. Покупатель вправе оформить заказ на любой Товар, представленный в интернет-магазине Презент. Исключения из данного правила указаны в описании каждого товара в случае проведения акций, снятия товара с продажи и т.п. 
<p>4.6. Продавец обеспечивает наличие на своем складе Товаров, представленных на Сайте. Сопровождающие Товар описания/характеристики не претендуют на исчерпывающую информативность и могут содержать опечатки. Для уточнения информации по Товару Покупатель должен обратиться в Службу поддержки клиентов. </p>
4.7. Заказ Покупателя оформляется в соответствии с процедурами, указанными на Сайте в разделе «Корзина». Заказ оформляется Покупателем в интернет-магазине Презент самостоятельно. 
<p>4.8. При оформлении заказа в интернет-магазине Презент Покупатель обязан предоставить о себе информацию:, имя, номер телефона, адрес электронной почты, реквизиты для доставки. </p>
4.9. Покупатель несет полную ответственность за предоставление неверных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем. 
<p>4.10. Интернет-магазин Презент не редактирует информацию о Покупателе. </p>
4.11. После оформления и оплаты заказа на телефон Покупателя отправляется смс с номером и подтверждение о принятии заказа.
<p>4.12. Дата и время передачи Заказа сообщается Покупателем в форме на сайте при заполнение данных.</p>
4.13. Дата передачи Товара может быть изменена Продавцом в одностороннем порядке в случае наличия объективных, по мнению Продавца, причин. 
<p>5. ОПЛАТА ТОВАРА</p>
5.1. Покупатель оплачивает заказ способом, указанным продавцом в интернет-магазине Презент
<p>5.2. Способы электронной оплаты, для интернет-магазина Презент: банковские карты VISA ,MasterCard, МИР</p>
5.3. Оплата заказа осуществляется в российских рублях. 
<p>5.4. Товар поставляется Покупателю по ценам, наименованию и в количестве, соответствующим заказу, оформленному и оплаченному Покупателем. </p>

<p>6. ДОСТАВКА ТОВАРА</p>
6.1. Территория доставки Товаров, представленных в интернет-магазине Презент, ограничена пределами Тобольска и Тобольского района. 
<p>6.2. Доставка товаров осуществляется транспортными компаниями или курьерскими службами на основании 100% оплаты товара по безналичному расчету. </p>
6.3. Стоимость доставки за  каждого Заказа рассчитывается индивидуально, исходя из удаленности адреса получателя.
<p>6.4. Задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца. </p>
6.5. Право собственности на Товар и риски случайного повреждения и/или гибели Товара переходят на Покупателя с момента вручение ему Товара.
<p>6.6. Обязанность Продавца передать товар Покупателю считается исполненной в момент вручения Заказа получателю.</p>
6.8. При принятии Заказа от курьера, Покупатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному количеству, ассортименту и комплектности Товара, а также проверить целостность упаковки. В случае отсутствия претензий к доставленному Товару курьером производится фото съёмка Покупателя , которая свидетельствует о том, что претензий к Товару Покупателем не заявлено и Продавец полностью и надлежащим образом выполнил свою обязанность по передаче Товара. Время нахождения курьера по адресу Покупателя ограничено 7 минутами. 
<p>6.9. Покупатель понимает и соглашается с тем, что: осуществление доставки — отдельная услуга, не являющаяся неотъемлемой частью приобретаемого Покупателем Товара, выполнение которой заканчивается в момент получения Получателем Товара. Претензии к качеству приобретенного Товара, возникшие после получения Товара, рассматриваются в соответствии с Законом РФ «О защите прав потребителей» и гарантийными обязательствами Продавца. В связи с этим приобретение Товара с доставкой не дает Покупателю право требования возврата денежных средств за услуги доставки приобретенного Товара.</p>
7. ПРАВА И ОБЯЗАННОСТИ СТОРОН.
<p>7.1. Продавец обязуется: </p>
7.1.1. Не разглашать любую частную информацию Покупателя и не предоставлять доступ к этой информации третьим лицам, за исключением случаев, предусмотренных Российским законодательством. 
<p>7.1.2. Предоставить Покупателю возможность получения бесплатных консультаций по телефонам и адресам электронной почты, указанным на сайте интернет-магазина Презент в разделе "Контакты" </p>
7.1.3. Продавец оставляет за собой право изменять настоящий договор в одностороннем порядке до момента его заключения. 
<p>7.1.4. Продавец оставляет за собой право расширять и сокращать товарное предложение на сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению. </p>
7.2. Покупатель обязуется: 
<p>7.2.1. До момента заключения договора ознакомиться с содержанием договора Оферты, условиями оплаты и доставки, характеристиками Товара, на сайте интернет - магазина Презент – www.презент72.рф</p>
7.2.2. Предоставлять достоверную информацию о себе (имя, , контактный телефон, адрес электронной почты, а так же детальную информацию об адресе доставки). 
<p>8. ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ.</p>
8.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, приобретенных в Интернет-магазине Презент. 
<p>8.2. Продавец не несет ответственности за содержание и функционирование внешних сайтов. </p>
8.3. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа. 
<p>8.4. Продавец не несет ответственности за доставку Товара, в случае, если Покупателем указан неправильный адрес доставки Товара. </p>
8.5. Продавец не несет ответственности, если ожидания Покупателя о потребительских свойствах Товара оказались не оправданны. 
<p>8.6. Продавец не несет ответственности за частичное или полное неисполнение обязательств по доставке Товара, если они являются следствием форс-мажорных обстоятельств. </p>
8.7. Покупатель, оформляя Заказ, несет ответственность за достоверность предоставляемой информации, а также подтверждает, что с условиями настоящего договора Оферты ознакомлен и согласен. 
<p>8.8. Все споры и разногласия, возникающие при исполнении Сторонами обязательств по настоящему договору, решаются путем переговоров. В случае невозможности их устранения, Стороны имеют право обратиться за судебной защитой своих интересов. </p>
8.9. Стороны несут ответственность за неисполнение или ненадлежащее исполнение настоящего договора Оферты в порядке, предусмотренном настоящим договором и действующим законодательством РФ. 
<p>9. ВОЗВРАТ ТОВАРОВ И ДЕНЕЖНЫХ СРЕДСТВ.</p>
9.1. Возврат Товара осуществляется в соответствии Законом РФ «О защите прав потребителей». 
<p>9.2. Возврат Товара надлежащего качества: В соответствии с Законом Российской Федерации «О защите прав потребителей» от 07.02.1992 № 2300-1 (в ред. от 25.10.2007г.) и Постановлением Правительства Российской Федерации от 19.01.1998 № 55 (в ред. 27.03.2007г.) срезанные цветы и горшечные растения обмену и возврату не подлежат (указаны в Перечне непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену).</p>
Покупатель Интернет-магазина Презент имеет право отказаться от получения товара в момент его доставки, если доставленный товар ненадлежащего качества (на основании п.3 ст. 497 ГК РФ, статья 21 Закона "О защите прав потребителей").
<p>9.2.1. Покупатель вправе отказаться от заказанного Товара в любое время до его передачи в службу доставки.</p>
9.2.2. При отказе Покупателя от Товара согласно п. 9.2.1. Продавец возвращает ему стоимость возвращенного Товара. Требования о возврате уплаченной за товар денежной суммы подлежат удовлетворению в течение 10 дней со дня предъявления соответствующего требования (ст. 22 Закона РФ «О защите прав потребителей»).
<p>9.3. Возврат Товара ненадлежащего качества: </p>
9.3.1. Отличие элементов дизайна, оформления от заявленного или искажения цвета из-за особенностей настроек монитора на Сайте описания не является признаком ненадлежащего качества. 
<p>9.3.2. Внешний вид и комплектность Товара, а также комплектность всего Заказа должны быть проверены Получателем в момент доставки Товара. </p>
9.3.3. После получения Заказа претензии к внешним дефектам товара, его количеству, комплектности и товарному виду не принимаются. 
<p>9.3.  Возврат денежных средств осуществляется посредством возврата стоимости оплаченного Товара в полном объеме на банковскую карту.</p>
10. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА
<p>10.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые при данных условиях обстоятельства, препятствующие исполнению своих обязательств Сторонами по настоящему Договору. К ним относятся стихийные явления (землетрясения, наводнения и т. п.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т. п.), запретительные меры государственных органов. В течение этого времени Стороны не имеют взаимных претензий, и каждая из Сторон принимает на себя свой риск последствия форс-мажорных обстоятельств. </p>
11. СРОК ДЕЙСТВИЯ ДОГОВОРА
<p>11.1. Настоящий договор вступает в силу с момента акцепта Покупателем данной Оферты и заканчивается при полном исполнении обязательств Сторонами. </p>

<p>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
12.1. Стороны подтверждают, что текст Договора содержит все согласованные Сторонами условия, относящиеся к предмету Договора. После заключения Договора все предварительные переговоры по нему, переписка, предварительные соглашения по вопросам, урегулированным Договором, теряют юридическую силу. 
<p>12.2. Ни одна из Сторон не вправе передавать (уступать, переводить) свои права и обязанности по Договору полностью или частично третьим лицам без письменного согласия другой Стороны.</p>
13.РЕКВИЗИТЫ
<p>Реквизиты:</p>

<p>ИП Закутянский Святослав Васильевич</p>
ОГРНИП 321723200021971
<p>ИНН: 720611080178</p>
Расчетный счет: 40802810267100034028
<p>Банк: ЗАПАДНО-СИБИРСКОЕ ОТДЕЛЕНИЕ№8647 ПАО </p>
СБЕРБАНК г. ТЮМЕНЬ
<p>БИК: 047102651</p>
<p>Корр. счет: 30101810800000000651</p>

            
            
            </Container>
        </>

    );

}

export default Oferta;